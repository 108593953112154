import React from 'react';
import PageLayout from '../components/PageLayout';

function Contact() {
  return (
    <PageLayout title="Contact">
      <div className="content-section">
        {/* Add your contact content here */}
        <p>Coming soon...</p>
      </div>
    </PageLayout>
  );
}

export default Contact; 