import React from 'react';
import PageLayout from '../components/PageLayout';

function Resume() {
  return (
    <PageLayout title="Resume">
      <div className="content-section">
        {/* Add your resume content here */}
        <p>Coming soon...</p>
      </div>
    </PageLayout>
  );
}

export default Resume; 